import { promiseWrapper } from 'utils/promise'

export interface TurnoverResponse {
  turnover: number
}

export interface EndValuesInterface {
  changedValue: string
  sumName: string
}

export const turnoverService = {
  fetchTurnover: (baseUrl) => {
    return promiseWrapper<TurnoverResponse, null>(`${baseUrl}/api/public/turnover10days`, 'GET')
  },
}
