import { turnoverService, EndValuesInterface } from 'services/turnover-service'
import { formatNumber } from 'utils/format-number'
import marketService from 'services/market-service'
import { WebWorkerPairMarketBrief } from 'models/market'

const DEFAULT_MARKET_BRIEF_REFRESH_INTERVAL = 30000
const DEFAULT_MARKET_BRIEF_REFRESH_BASEURL = 'https://user.walutomat.pl'
const DEFAULT_MARKET_BRIEF_REFRESH_BASEBIZURL = 'https://biz.walutomat.pl'

/**
 * Example worker task

  const <<action>> = () => {
    // Do whatever you want...
    postMessage({
      action: '<<actionEventName>>',
      payload: null,
    })
  }
*/

/**
 * Send ACK. Interval 1000ms
 *
 */
const tickTockInterval = (): void => postMessage({ action: 'tickTock' })

/**
 * Fetch Market Brief in given interval
 * Default: 30000ms
 *
 * @param {string} baseUrl
 */

const fetchMarketBrief = (baseUrl): void => {
  marketService
    .fetchMarketBrief(baseUrl)
    .then((data): void => {
      postMessage({
        action: 'marketBriefUpdate',
        payload: data,
      })
    })
    .catch(() => {
      console.debug('[API] marketBrief.fetchMarketBrief failure.')
    })
}

const fetchPairMarketBrief = (baseUrl: string, baseCurrency: string, counterCurrency: string): void => {
  marketService
    .fetchPairMarketBrief(baseUrl, baseCurrency, counterCurrency)
    .then((data): void => {
      postMessage({
        action: 'marketPairBriefUpdate',
        payload: data,
      })
    })
    .catch(() => {
      console.debug('[API] marketBrief.fetchMarketBrief failure.')
    })
}

const fetchMarketBriefBiz = (baseUrl): void => {
  marketService
    .fetchMarketBriefBiz(baseUrl)
    .then((data): void => {
      postMessage({
        action: 'marketBriefUpdate',
        payload: data,
      })
    })
    .catch(() => {
      console.debug('[API] marketBrief.fetchMarketBriefBiz failure.')
    })
}

const fetchTurnover = (baseUrl): void => {
  turnoverService
    .fetchTurnover(baseUrl)
    .then((data): void => {
      postMessage({
        action: 'wtTurnoverUpdate',
        payload: checkValue(data.turnover),
      })
    })
    .catch(() => {
      console.debug('[API] turnoverService.fetchTurnover failure.')
    })
}

const checkValue = (plnTurnoverValue: number): EndValuesInterface => {
  // TODO: for rest languages
  // const isBillion = plnTurnoverValue >= 1000000000
  // const rightTurnoverFormat = isBillion
  //   ? formatNumber(plnTurnoverValue / 1000000000, 1)
  //   : formatNumber(plnTurnoverValue / 1000000, 0)
  // const textEnd = isBillion ? 'mld' : 'milionów'
  const rightTurnoverFormat = formatNumber(plnTurnoverValue / 1000000, 0)
  const textEnd = 'milionów'
  const values = {
    changedValue: rightTurnoverFormat.toString(),
    sumName: textEnd.toString(),
  }
  return values
}

/**
 * Declare jobs that this worker should do.
 *
 * @param {string} actionType
 * @param {*} payload
 */
const workerHandler = (actionType: string, payload: any): void => {
  const workerJobs = {
    //<<action>>: ():void => {
    //  newActionToPerform()
    //}
    initMarketBrief: (payload): void => {
      const interval = payload.interval || DEFAULT_MARKET_BRIEF_REFRESH_INTERVAL
      const baseUrl = payload.baseUrl || DEFAULT_MARKET_BRIEF_REFRESH_BASEURL

      fetchMarketBrief(baseUrl)
      setInterval(() => fetchMarketBrief(baseUrl), interval)
    },
    initPairMarketBrief: (payload: WebWorkerPairMarketBrief): void => {
      const interval = payload.interval || DEFAULT_MARKET_BRIEF_REFRESH_INTERVAL
      const baseUrl = payload.baseUrl || DEFAULT_MARKET_BRIEF_REFRESH_BASEURL

      fetchPairMarketBrief(baseUrl, payload.baseCurrency, payload.counterCurrency)
      setInterval(() => fetchPairMarketBrief(baseUrl, payload.baseCurrency, payload.counterCurrency), interval)
    },
    initMarketBriefBiz: (payload): void => {
      const interval = payload.interval || DEFAULT_MARKET_BRIEF_REFRESH_INTERVAL
      const baseUrl = DEFAULT_MARKET_BRIEF_REFRESH_BASEBIZURL

      fetchMarketBriefBiz(baseUrl)
      setInterval(() => fetchMarketBriefBiz(baseUrl), interval)
    },
    initTickTock: (): void => {
      setInterval(tickTockInterval, 1000)
    },
    initTurnover: (payload): void => {
      const baseUrl = payload.baseUrl || DEFAULT_MARKET_BRIEF_REFRESH_BASEURL

      fetchTurnover(baseUrl)
    },
    missingAction: (): void => {
      console.warn('[Worker] Idle worker run')
    },
  }
  workerJobs[actionType] ? workerJobs[actionType](payload) : workerJobs['missingAction']()
}

self.onmessage = (event: MessageEvent): void => {
  const data = event?.data
  const [action = 'missingAction', payload = null] = Array.isArray(data) ? data : []
  workerHandler(action, payload)
}
