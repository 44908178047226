import { promiseWrapper } from 'utils/promise'
import { MarketBrief, MarketBriefBiz, MarketEstimate, MarketHistory, MarketOffers } from 'models/market'
import { ChartTimespan } from 'models/chart'

const marketService = {
  fetchMarketHistory: (
    baseUrl: string,
    baseCurrency: string,
    counterCurrency: string,
    timeSpan: ChartTimespan,
  ): Promise<MarketHistory> => {
    return promiseWrapper<MarketHistory, null>(
      `${baseUrl}/api/public/marketHistory/${baseCurrency}_${counterCurrency}/${timeSpan}`,
      'GET',
    )
  },
  fetchMarketBrief: (baseUrl: string): Promise<MarketBrief[]> => {
    return promiseWrapper<MarketBrief[], null>(`${baseUrl}/api/public/marketBrief`, 'GET')
  },
  fetchPairMarketBrief: (baseUrl: string, baseCurrency: string, counterCurrency: string): Promise<MarketBrief> => {
    return promiseWrapper<MarketBrief, null>(
      `${baseUrl}/api/public/marketBrief/${baseCurrency}_${counterCurrency}`,
      'GET',
    )
  },
  fetchMarketBriefBiz: (baseUrl: string): Promise<MarketBriefBiz[]> => {
    return promiseWrapper<MarketBriefBiz[], null>(`${baseUrl}/api/public/business/fxBrief`, 'GET')
  },
  fetchMarketPriceVolumes: (baseUrl: string, currencyPair: string, scope: string) => {
    return promiseWrapper<MarketOffers, null>(
      `${baseUrl}/api/public/marketPriceVolumes/${currencyPair}/${scope}`,
      'GET',
    )
  },
  fetchMarketEstimate: (
    baseUrl: string,
    direction: string,
    value: string,
    sellCurrency: string,
    buyCurrency: string,
  ) => {
    return promiseWrapper<MarketEstimate, null>(
      `${baseUrl}/api/public/marketEstimate/${direction}/${value}/${sellCurrency}/${buyCurrency}`,
      'GET',
    )
  },
}

export default marketService
